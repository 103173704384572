import * as React from "react"
import { useRef, useEffect } from "react"
import emailjs from "@emailjs/browser"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/contact.css"
import contactImage from "../images/contact-image.svg"
import instagram from "../images/instagram.svg"
import mail from "../images/mail.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Contact = () => {
  const { t } = useTranslation()
  const form = useRef()
  useEffect(() => {
    document.querySelector("body").classList = ["contact"]
    document.querySelector("html").classList = ["contact"]
  }, [])
  const sendEmail = e => {
    e.preventDefault()
    emailjs
      .sendForm(
        "service_4k9orq9",
        "template_12tkfwv",
        form.current,
        "user_3sY93mxpyMnLFIyvM3pA5"
      )
      .then(
        () => {
          window.location.reload()
        },
        error => {
          console.log(error.text)
        }
      )
  }
  return (
    <Layout>
      <Seo title={t("IletisimSEO")} />
      <div className="mt-40 relative flex items-center justify-center md:mt-28">
        <div className="max-w-full overflow-hidden wrapper">
          <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default md:mb-0 opacity-[.15]">
              {t("IletisimPage")}
            </p>
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default md:mb-0 opacity-[.15]">
              {t("IletisimPage")}
            </p>
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default md:mb-0 opacity-[.15]">
              {t("IletisimPage")}
            </p>
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default md:mb-0 opacity-[.15]">
              {t("IletisimPage")}
            </p>
          </div>
        </div>
        <h1 className="mb-0 font-first text-default text-5xl font-semibold absolute m-auto md:text-3xl -mt-10 md:-mt-1">
          {t("IletisimPage")}
        </h1>
      </div>
      <div className="flex w-full justify-between items-start mx-auto mb-5 md:flex-col">
        <div className="flex flex-col items-center w-5/12 md:w-full md:mb-5">
          <div className="w-8/12 flex flex-col items-start md:w-11/12">
            <h3 className="mb-10 text-default relative font-normal text-[26px] font-first before:absolute flex items-center before:-right-40 md:before:right-0 before:h-[2px] before:w-8/12 before:bg-default md:text-center md:w-full md:flex md:justify-center md:before:mx-auto md:text-3xl md:before:h-[2px] md:pb-4 md:mb-6 md:before:left-0 md:before:bottom-0 md:before:w-7/12">
              {t("FormTitle")}
            </h3>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="w-full flex flex-wrap justify-between items-end"
            >
              <input
                type="text"
                name="user_name"
                placeholder={t("FormAd")}
                className="w-[47%] h-14 mb-10 rounded-md p-3 font-first text-default placeholder:text-default placeholder:opacity-90 text-base md:w-full md:mb-5"
              />
              <input
                type="text"
                name="user_surname"
                placeholder={t("FormSoyad")}
                className="w-[47%] h-14 mb-10 rounded-md p-3 font-first text-default placeholder:text-default placeholder:opacity-90 text-base md:w-full md:mb-5"
              />
              <input
                type="email"
                name="user_email"
                placeholder={t("FormMail")}
                className="w-full h-14 mb-10 rounded-md p-3 font-first text-default placeholder:text-default placeholder:opacity-90 text-base md:mb-5"
              />
              <textarea
                name="message"
                placeholder={t("FormMessage")}
                className="w-8/12 md:w-full md:mb-5 rounded-md p-3 font-first text-default placeholder:text-default placeholder:opacity-90 text-base resize-none"
                rows={6}
              />
              <input
                type="submit"
                value={t("FormSubmit")}
                className="h-14 rounded-md bg-default text-white font-first font-light text-lg w-[28%] cursor-pointer border-2 border-default hover:bg-transparent hover:text-default transition-all duration-300 md:w-full"
              />
            </form>
            <div className="w-full mt-20 flex justify-between items-center md:mt-5 md:flex-col">
              <div className="flex md:mb-3">
                <a
                  href="https://instagram.com/mgenanmimarlik"
                  role={"button"}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-3"
                >
                  <img src={instagram} alt="instagram logo" />
                </a>
              </div>
              <a
                href="mailto:mgenenmimarlik@gmail.com"
                className="font-first text-default text-base border-2 flex items-center pr-3 border-default rounded-lg font-semibold"
              >
                <img src={mail} alt="mail icon" className="mr-3" />
                mgenanmimarlik@gmail.com
              </a>
            </div>
          </div>
        </div>
        <div className="w-7/12 md:hidden">
          <img src={contactImage} alt="about" className="w-10/12" />
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
